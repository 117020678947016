import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import { contactFormSchema } from './schema';
import { Input } from '../../common/Input';
import { useToast } from '../../../context/ToastContext';
import { cn } from '../../../lib/utils';
import type { ContactFormData } from './types';

interface ContactFormProps {
  onSubmit: (data: ContactFormData) => Promise<void>;
  className?: string;
}

export function ContactForm({ onSubmit, className }: ContactFormProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showToast } = useToast();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ContactFormData>({
    resolver: zodResolver(contactFormSchema)
  });

  const handleFormSubmit = async (data: ContactFormData) => {
    setIsSubmitting(true);
    try {
      await onSubmit(data);
      showToast('Support request submitted successfully', 'success');
      reset();
    } catch (error) {
      showToast('Failed to submit support request', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className={cn('space-y-6', className)}>
      <Input
        label="Subject"
        error={errors.subject?.message}
        {...register('subject')}
        required
      />

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Category
          <span className="text-red-500 ml-1">*</span>
        </label>
        <select
          {...register('category')}
          className={cn(
            'block w-full rounded-md shadow-sm sm:text-sm',
            'border border-gray-300 focus:border-brand-500 focus:ring-brand-500',
            'h-10 py-2 px-3'
          )}
        >
          <option value="">Select a category</option>
          <option value="technical">Technical Issue</option>
          <option value="billing">Billing Question</option>
          <option value="feature">Feature Request</option>
          <option value="account">Account Help</option>
          <option value="other">Other</option>
        </select>
        {errors.category?.message && (
          <p className="mt-1 text-sm text-red-600">{errors.category.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Description
          <span className="text-red-500 ml-1">*</span>
        </label>
        <textarea
          {...register('description')}
          rows={5}
          className={cn(
            'block w-full rounded-md shadow-sm sm:text-sm',
            'border border-gray-300 focus:border-brand-500 focus:ring-brand-500',
            'py-2 px-3'
          )}
          placeholder="Please describe your issue or question in detail..."
        />
        {errors.description?.message && (
          <p className="mt-1 text-sm text-red-600">{errors.description.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Priority
        </label>
        <select
          {...register('priority')}
          className={cn(
            'block w-full rounded-md shadow-sm sm:text-sm',
            'border border-gray-300 focus:border-brand-500 focus:ring-brand-500',
            'h-10 py-2 px-3'
          )}
        >
          <option value="low">Low</option>
          <option value="medium">Medium</option>
          <option value="high">High</option>
          <option value="urgent">Urgent</option>
        </select>
      </div>

      <button
        type="submit"
        disabled={isSubmitting}
        className={cn(
          'w-full flex justify-center py-2 px-4 border border-transparent',
          'rounded-md shadow-sm text-sm font-medium text-white',
          'bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2',
          'focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50'
        )}
      >
        {isSubmitting ? (
          <>
            <Loader2 className="w-4 h-4 mr-2 animate-spin" />
            Submitting...
          </>
        ) : (
          'Submit Request'
        )}
      </button>
    </form>
  );
}