import { createContext, useContext, ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface HelpContextType {
  currentSection: string;
  currentTopic: string | null;
  setCurrentSection: (section: string) => void;
  setCurrentTopic: (topic: string | null) => void;
  helpLink: string;
}

const HelpContext = createContext<HelpContextType | undefined>(undefined);

export function HelpProvider({ children }: { children: ReactNode }) {
  const location = useLocation();
  const [currentSection, setCurrentSection] = useState('guide');
  const [currentTopic, setCurrentTopic] = useState<string | null>(null);

  const helpLink = `/help/${currentSection}${currentTopic ? `#${currentTopic}` : ''}`;

  return (
    <HelpContext.Provider value={{
      currentSection,
      currentTopic,
      setCurrentSection,
      setCurrentTopic,
      helpLink
    }}>
      {children}
    </HelpContext.Provider>
  );
}

export function useHelpContext() {
  const context = useContext(HelpContext);
  if (context === undefined) {
    throw new Error('useHelpContext must be used within a HelpProvider');
  }
  return context;
}