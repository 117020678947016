import { useNavigate } from 'react-router-dom';
import { cn } from '../../lib/utils';
import type { MascotMessage } from './types';

interface MascotSpeechBubbleProps {
  message: MascotMessage;
}

export function MascotSpeechBubble({ message }: MascotSpeechBubbleProps) {
  const navigate = useNavigate();

  if (!message.text) return null;

  const handleClick = () => {
    if (message.action) {
      switch (message.action.type) {
        case 'navigate_tasks':
          navigate(message.action.data?.path || '/tasks');
          break;
        case 'navigate_tutorials':
          navigate('/help/tutorials');
          break;
        case 'navigate_faq':
          navigate('/help/faq');
          break;
        case 'navigate_support':
          navigate('/help/support');
          break;
        case 'navigate_guide':
          navigate('/help/guide');
          break;
        default:
          console.warn('Unknown action type:', message.action.type);
      }
    }
  };

  return (
    <div 
      onClick={handleClick}
      className={cn(
        // Position and size
        'absolute bottom-full right-0 mb-4',
        'min-w-[200px] max-w-[300px]',
        
        // Background and border
        'bg-white rounded-2xl shadow-lg',
        'border-2 border-gray-200',
        
        // Padding and text
        'p-4',
        
        // Animation
        'animate-fade-in transform-gpu',
        
        // Interactive states
        message.action && [
          'cursor-pointer',
          'hover:bg-gray-50',
          'hover:border-brand-200',
          'hover:shadow-xl',
          'transition-all duration-200'
        ]
      )}
    >
      {/* Message Text */}
      <div className="text-sm text-gray-700 mb-2">
        {message.text}
      </div>

      {/* Suggestions */}
      {message.suggestions && message.suggestions.length > 0 && (
        <div className="mt-3 space-y-2">
          {message.suggestions.map((suggestion, index) => (
            <div 
              key={index}
              className="text-xs text-brand-600 hover:text-brand-700 cursor-pointer"
            >
              • {suggestion}
            </div>
          ))}
        </div>
      )}

      {/* Related Links */}
      {message.relatedLinks && message.relatedLinks.length > 0 && (
        <div className="mt-3 space-y-2">
          {message.relatedLinks.map((link, index) => (
            <a
              key={index}
              href={link.url}
              className="block text-xs text-brand-600 hover:text-brand-700 hover:underline"
            >
              → {link.text}
            </a>
          ))}
        </div>
      )}

      {/* Action Link */}
      {message.action && (
        <div className="mt-3 text-xs font-medium text-brand-600 flex items-center">
          Click to learn more
          <svg className="w-3 h-3 ml-1" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </div>
      )}

      {/* Speech Bubble Tail */}
      <div className={cn(
        'absolute -bottom-3 right-6',
        'w-4 h-4 bg-white transform rotate-45',
        'border-r-2 border-b-2 border-gray-200',
        message.action && 'group-hover:border-brand-200'
      )} />
    </div>
  );
}