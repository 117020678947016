import { useMemo } from 'react';
import { format, isToday, isSameMonth, isWeekend } from 'date-fns';
import { cn } from '../../../../lib/utils';
import { TimelineViewMode } from './types';
import type { TimelineData } from './types';

interface TimelineGridProps {
  data: TimelineData;
  viewMode: TimelineViewMode;
}

export function TimelineGrid({ data, viewMode }: TimelineGridProps) {
  const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  // Group tasks by date
  const tasksByDate = useMemo(() => {
    const grouped: Record<string, typeof data.tasks> = {};
    data.dates.forEach(date => {
      const dateStr = date.toDateString();
      grouped[dateStr] = data.tasks.filter(task => 
        task.dates.some(taskDate => taskDate.toDateString() === dateStr)
      );
    });
    return grouped;
  }, [data.tasks, data.dates]);

  return (
    <div className="overflow-auto">
      <div className="min-w-full border border-gray-200 rounded-lg bg-white shadow-sm">
        {/* Column Headers */}
        <div className="grid grid-cols-7 sticky top-0 z-10 bg-white border-b border-gray-200">
          {weekDays.map((day) => (
            <div
              key={day}
              className={cn(
                'p-4 text-center border-r border-gray-200',
                day === 'Sun' || day === 'Sat' ? 'bg-gray-50/70' : 'bg-white'
              )}
            >
              <span className="text-xs text-gray-500">{day}</span>
            </div>
          ))}
        </div>

        {/* Calendar Grid */}
        <div className="grid grid-cols-7 min-h-[600px]">
          {data.dates.map(date => {
            const dateStr = date.toDateString();
            const dayTasks = tasksByDate[dateStr] || [];
            const isWeekendDay = isWeekend(date);
            const isTodays = isToday(date);
            const isCurrentMonths = isSameMonth(date, new Date());

            return (
              <div
                key={date.toISOString()}
                className={cn(
                  'min-h-[150px] border-b border-r border-gray-200',
                  isTodays ? 'bg-brand-50/20' : isWeekendDay ? 'bg-gray-50/50' : 'bg-white',
                  'hover:bg-gray-50/80 transition-colors'
                )}
              >
                {/* Date Number */}
                <div className={cn(
                  'p-2 border-b border-gray-100',
                  isTodays ? 'bg-brand-100' : 'bg-gray-50/50'
                )}>
                  <span className={cn(
                    'inline-flex items-center justify-center w-6 h-6 rounded-full text-sm',
                    isTodays ? 'bg-brand-500 text-white' : 
                    isCurrentMonths ? 'text-gray-900' : 'text-gray-400'
                  )}>
                    {format(date, 'd')}
                  </span>
                </div>

                {/* Tasks */}
                <div className="p-1">
                  {dayTasks.length > 0 ? (
                    <div className="space-y-1">
                      {dayTasks.map(task => (
                        <div
                          key={task.id}
                          className={cn(
                            'p-2 rounded-md text-xs border transition-all',
                            'hover:shadow-sm cursor-pointer',
                            'bg-white border-gray-200',
                            'hover:border-brand-200 hover:bg-brand-50/50'
                          )}
                        >
                          <div className="font-mono text-gray-500 text-[10px] mb-0.5">
                            TSK-{task.id.toString().padStart(4, '0')}
                          </div>
                          <div className="font-medium text-gray-900 truncate">
                            {task.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="h-full flex items-center justify-center">
                      <span className="text-xs text-gray-400">No tasks</span>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}