import { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { cn } from '../../lib/utils';

const FAQ_ITEMS = [
  {
    question: 'How do I customize my dashboard?',
    answer: 'Click the "Customize Dashboard" button in the top right corner. You can then drag and drop widgets, toggle their visibility, and save your preferred layout.'
  },
  {
    question: 'Can I assign multiple people to a task?',
    answer: 'Currently, tasks can only be assigned to one person at a time. However, you can use subtasks to break down work and assign different parts to different team members.'
  },
  {
    question: 'How do I track task progress?',
    answer: 'You can track task progress through the Task Overview widget on your dashboard, or by using the Timeline view in the Tasks section. Each task also shows its current status and completion percentage.'
  },
  {
    question: 'What do the different task priorities mean?',
    answer: 'Tasks can be set to Low, Medium, or High priority. High priority tasks appear in the Priority Tasks widget and are highlighted to ensure they get immediate attention.'
  }
];

export function FAQSection() {
  const [openItems, setOpenItems] = useState<number[]>([]);

  const toggleItem = (index: number) => {
    setOpenItems(prev => 
      prev.includes(index)
        ? prev.filter(i => i !== index)
        : [...prev, index]
    );
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-lg font-medium text-gray-900 mb-6">
        Frequently Asked Questions
      </h2>

      <div className="space-y-4">
        {FAQ_ITEMS.map((item, index) => (
          <div
            key={index}
            className={cn(
              'border rounded-lg overflow-hidden',
              openItems.includes(index) ? 'border-brand-200' : 'border-gray-200'
            )}
          >
            <button
              onClick={() => toggleItem(index)}
              className={cn(
                'w-full flex items-center justify-between p-4',
                'text-left text-gray-900 font-medium',
                'hover:bg-gray-50 transition-colors',
                openItems.includes(index) && 'bg-brand-50'
              )}
            >
              <span>{item.question}</span>
              {openItems.includes(index) ? (
                <ChevronUp className="w-5 h-5 text-brand-500" />
              ) : (
                <ChevronDown className="w-5 h-5 text-gray-400" />
              )}
            </button>

            {openItems.includes(index) && (
              <div className="px-4 pb-4 text-gray-600">
                {item.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}