import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Toaster } from 'react-hot-toast';

// Providers
import { TenantProvider } from './context/TenantContext';
import { OrganizationProvider } from './context/OrganizationContext';
import { ToastProvider } from './context/ToastContext';
import { HelpProvider } from './components/help/HelpContext';

// Components
import Layout from './components/Layout';
import Login from './components/Login';
import LoadingScreen from './components/LoadingScreen';
import ErrorBoundary from './components/ErrorBoundary';
import ProtectedRoute from './components/ProtectedRoute';

// Pages
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import OnboardingFlow from './pages/OnboardingFlow';
import Users from './pages/Users';
import OrganizationSettings from './pages/OrganizationSettings';
import AcceptInvite from './pages/AcceptInvite';
import { TasksPage } from './pages/tasks/TasksPage';
import { TaskDetailsPage } from './pages/tasks/TaskDetailsPage';
import { TaskSharePage } from './pages/tasks/TaskSharePage';
import { SubscriptionPage } from './pages/SubscriptionPage';

// Help Section Components
import { HelpLayout } from './components/help/HelpLayout';
import { GuideContent } from './components/help/GuideContent';
import { VideoTutorials } from './components/help/VideoTutorials';
import { FAQSection } from './components/help/FAQSection';
import { ContactSupport } from './components/help/ContactSupport/ContactSupport';

export function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <ErrorBoundary>
      <ToastProvider>
        <OrganizationProvider>
          <TenantProvider>
            <HelpProvider>
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/accept-invite" element={<AcceptInvite />} />
                <Route path="/tasks/share/:token" element={<TaskSharePage />} />

                {/* Protected Routes */}
                <Route element={<Layout />}>
                  {/* Main Routes */}
                  <Route
                    path="/dashboard"
                    element={
                      <ProtectedRoute>
                        <Dashboard />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/tasks"
                    element={
                      <ProtectedRoute>
                        <TasksPage />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/tasks/:taskId"
                    element={
                      <ProtectedRoute>
                        <TaskDetailsPage />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/users"
                    element={
                      <ProtectedRoute>
                        <Users />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/settings"
                    element={
                      <ProtectedRoute>
                        <Settings />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/organization-settings"
                    element={
                      <ProtectedRoute>
                        <OrganizationSettings />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/subscription"
                    element={
                      <ProtectedRoute>
                        <SubscriptionPage />
                      </ProtectedRoute>
                    }
                  />

                  {/* Help Section Routes */}
                  <Route path="/help" element={<HelpLayout />}>
                    <Route index element={<Navigate to="/help/guide" replace />} />
                    <Route path="guide" element={<GuideContent />} />
                    <Route path="tutorials" element={<VideoTutorials />} />
                    <Route path="faq" element={<FAQSection />} />
                    <Route path="support" element={<ContactSupport />} />
                  </Route>
                </Route>

                {/* Catch all route */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>

              <Toaster position="top-right" />
            </HelpProvider>
          </TenantProvider>
        </OrganizationProvider>
      </ToastProvider>
    </ErrorBoundary>
  );
}