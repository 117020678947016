import { NavLink, useNavigate } from 'react-router-dom';
import { Book, Video, MessageCircle, HelpCircle } from 'lucide-react';
import { cn } from '../../lib/utils';
import { ContactPrompt } from './ContactPrompt';

const helpSections = [
  {
    title: 'SUPPORT',
    items: [
      { path: '/help/support', icon: MessageCircle, label: 'Contact Support' }
    ]
  },
  {
    title: 'GETTING STARTED',
    items: [
      { path: '/help/guide', icon: Book, label: 'User Guide' },
      { path: '/help/tutorials', icon: Video, label: 'Video Tutorials' },
      { path: '/help/faq', icon: HelpCircle, label: 'FAQ' }
    ]
  }
];

export function HelpSidebar() {
  const navigate = useNavigate();

  return (
    <div className="fixed w-64 h-full bg-white border-r border-gray-200">
      <div className="h-full py-6 overflow-y-auto">
        {helpSections.map((section) => (
          <div key={section.title} className="px-3 mb-6">
            <h3 className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider">
              {section.title}
            </h3>
            <div className="mt-2 space-y-1">
              {section.items.map((item) => (
                <NavLink
                  key={item.path}
                  to={item.path}
                  className={({ isActive }) => cn(
                    'group flex items-center px-3 py-2 text-sm font-medium rounded-md',
                    isActive
                      ? 'bg-brand-50 text-brand-700'
                      : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
                  )}
                >
                  <item.icon className="mr-3 h-5 w-5 flex-shrink-0" />
                  {item.label}
                </NavLink>
              ))}
            </div>
          </div>
        ))}

        {/* Contact Prompt */}
        <div className="px-3">
          <ContactPrompt />
        </div>
      </div>
    </div>
  );
}