import { MessageCircle, Mail, Clock } from 'lucide-react';
import { ContactForm } from './ContactForm';
import { useContactSupport } from './hooks/useContactSupport';
import { cn } from '../../../lib/utils';
import type { ContactFormData } from './types';

export function ContactSupport() {
  const { submitRequest, isLoading } = useContactSupport();

  const handleSubmit = async (data: ContactFormData) => {
    await submitRequest(data);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        {/* Header */}
        <div className="mb-8">
          <h2 className="text-lg font-medium text-gray-900">Contact Support</h2>
          <p className="mt-1 text-sm text-gray-500">
            Need help? Submit a support request and we'll get back to you as soon as possible.
          </p>
        </div>

        {/* Support Channels */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {[
            {
              icon: MessageCircle,
              title: 'Live Chat',
              description: 'Chat with our support team in real-time',
              available: true
            },
            {
              icon: Mail,
              title: 'Email Support',
              description: 'Get help via email within 24 hours',
              available: true
            },
            {
              icon: Clock,
              title: 'Response Time',
              description: 'Average response time: 2-4 hours',
              info: true
            }
          ].map((channel) => (
            <div
              key={channel.title}
              className={cn(
                'p-4 rounded-lg border',
                channel.available
                  ? 'border-brand-200 bg-brand-50'
                  : channel.info
                  ? 'border-gray-200 bg-gray-50'
                  : 'border-gray-200 bg-gray-50 opacity-50'
              )}
            >
              <div className="flex items-center space-x-3">
                <channel.icon className={cn(
                  'w-5 h-5',
                  channel.available
                    ? 'text-brand-600'
                    : channel.info
                    ? 'text-gray-600'
                    : 'text-gray-400'
                )} />
                <div>
                  <h3 className="text-sm font-medium text-gray-900">
                    {channel.title}
                  </h3>
                  <p className="mt-1 text-xs text-gray-500">
                    {channel.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Support Form */}
        <ContactForm onSubmit={handleSubmit} />
      </div>
    </div>
  );
}