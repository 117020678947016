import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useEmotionState } from './useEmotionState';
import { useFAQMascot } from './useFAQMascot';
import { useTutorialMascot } from './useTutorialMascot';
import { getContextualMessage } from '../constants/contextualMessages';
import { MascotMessage } from '../types';

export function useContextualMascot() {
  const location = useLocation();
  const { emotion, updateEmotion } = useEmotionState();
  const faqMascot = useFAQMascot();
  const tutorialMascot = useTutorialMascot();

  useEffect(() => {
    // Update emotion based on current route
    if (location.pathname.includes('/help/faq')) {
      updateEmotion('happy');
    } else if (location.pathname.includes('/help/tutorials')) {
      updateEmotion('neutral');
    } else if (location.pathname.includes('/help/support')) {
      updateEmotion('happy');
    }
  }, [location, updateEmotion]);

  const getMessage = (): MascotMessage => {
    // Help section specific messages
    if (location.pathname.includes('/help/faq')) {
      return faqMascot.message;
    }
    if (location.pathname.includes('/help/tutorials')) {
      return tutorialMascot.message;
    }
    if (location.pathname.includes('/help/support')) {
      return {
        text: "Need assistance? I'm here to help you get in touch with our support team! 🤝",
        action: {
          type: 'navigate_support'
        }
      };
    }
    if (location.pathname.includes('/help/guide')) {
      return {
        text: "Exploring our guides? Let me know if you need help finding anything! 📚",
        action: {
          type: 'navigate_guide'
        }
      };
    }

    // Get contextual message for other sections
    return getContextualMessage(location.pathname);
  };

  return {
    emotion,
    message: getMessage()
  };
}