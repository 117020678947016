import { memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { cn } from '../../lib/utils';
import {
  TaskOverviewWidget,
  MyTasksWidget,
  TeamWorkloadWidget,
  TimelineWidget,
  AnalyticsWidget,
  RecentActivityWidget,
  PriorityTasksWidget,
  CustomReportsWidget,
  CommentThreadWidget
} from './widgets';
import type { DashboardLayout } from '../../types/dashboard';

interface DashboardGridProps {
  layout: DashboardLayout;
}

const widgetComponents = {
  taskOverview: TaskOverviewWidget,
  myTasks: MyTasksWidget,
  teamWorkload: TeamWorkloadWidget,
  timeline: TimelineWidget,
  analytics: AnalyticsWidget,
  recentActivity: RecentActivityWidget,
  priorityTasks: PriorityTasksWidget,
  customReports: CustomReportsWidget,
  commentThread: CommentThreadWidget
};

// Define standard widget heights
const WIDGET_HEIGHTS = {
  small: 'min-h-[300px]',
  medium: 'min-h-[400px]',
  large: 'min-h-[500px]'
};

// Define widget size configurations
const WIDGET_SIZES = {
  taskOverview: { height: 'small', span: 'col-span-2' },
  myTasks: { height: 'medium', span: 'col-span-1' },
  teamWorkload: { height: 'medium', span: 'col-span-1' },
  timeline: { height: 'large', span: 'col-span-2' },
  analytics: { height: 'large', span: 'col-span-1' },
  recentActivity: { height: 'medium', span: 'col-span-1' },
  priorityTasks: { height: 'medium', span: 'col-span-1' },
  customReports: { height: 'medium', span: 'col-span-1' },
  commentThread: { height: 'medium', span: 'col-span-1' }
};

export const DashboardGrid = memo(({ layout }: DashboardGridProps) => {
  return (
    <>
      {layout.filter(widget => widget.visible).map((widget, index) => {
        const Widget = widgetComponents[widget.type];
        if (!Widget) return null;

        const size = WIDGET_SIZES[widget.type];

        return (
          <Draggable key={widget.id} draggableId={widget.id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={cn(
                  // Base styles
                  'bg-white rounded-lg border border-gray-200',
                  'transition-all duration-200',
                  
                  // Height based on widget type
                  WIDGET_HEIGHTS[size.height as keyof typeof WIDGET_HEIGHTS],
                  
                  // Column span
                  size.span,
                  
                  // Drag state
                  snapshot.isDragging && 'shadow-lg ring-2 ring-brand-500 ring-opacity-50',
                  
                  // Hover state
                  'hover:shadow-md hover:border-gray-300'
                )}
              >
                <Widget />
              </div>
            )}
          </Draggable>
        );
      })}
    </>
  );
});

DashboardGrid.displayName = 'DashboardGrid';