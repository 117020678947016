import { cn } from '../../lib/utils';
import type { MascotEmotion } from './types';

interface MascotEmotionsProps {
  emotion: MascotEmotion;
  isBlinking: boolean;
  isHovered: boolean;
}

export function MascotEmotions({ emotion, isBlinking, isHovered }: MascotEmotionsProps) {
  const getEmotionStyles = () => {
    switch (emotion) {
      case 'happy':
        return 'bg-green-100 border-green-200';
      case 'sad':
        return 'bg-red-100 border-red-200';
      case 'worried':
        return 'bg-yellow-100 border-yellow-200';
      default:
        return 'bg-brand-100 border-brand-200';
    }
  };

  const getEyeColor = () => {
    switch (emotion) {
      case 'happy':
        return 'bg-green-600';
      case 'sad':
        return 'bg-red-600';
      case 'worried':
        return 'bg-yellow-600';
      default:
        return 'bg-brand-600';
    }
  };

  const getMouthStyles = () => {
    switch (emotion) {
      case 'happy':
        return 'bg-green-600 animate-smile h-3';
      case 'sad':
        return 'bg-red-600 animate-frown';
      case 'worried':
        return 'bg-yellow-600 animate-wobble';
      default:
        return 'bg-brand-600';
    }
  };

  return (
    <div className={cn(
      'w-full h-full rounded-full border-2',
      'flex items-center justify-center transition-all duration-300',
      'shadow-lg hover:shadow-xl',
      getEmotionStyles(),
      isHovered && 'transform scale-105'
    )}>
      {/* Face Container */}
      <div className="relative flex flex-col items-center">
        {/* Eyes */}
        <div className={cn(
          'flex space-x-3 mb-2',
          isBlinking && 'animate-blink'
        )}>
          <div className={cn(
            'w-2 h-2 rounded-full transition-all',
            getEyeColor()
          )} />
          <div className={cn(
            'w-2 h-2 rounded-full transition-all',
            getEyeColor()
          )} />
        </div>
        
        {/* Mouth */}
        <div className={cn(
          'w-6 h-2 rounded-full transition-all duration-300',
          getMouthStyles(),
          isHovered && 'transform scale-110'
        )} />
      </div>
    </div>
  );
}