import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { LogOut } from 'lucide-react';
import Sidebar from './Sidebar';
import OrganizationSwitcher from './OrganizationSwitcher';
import { NotificationBell } from './common/NotificationBell';
import { ContextualMascot } from './mascot/ContextualMascot';
import { MascotToggle } from './mascot/MascotToggle';
import { usePermissions } from '../hooks/usePermissions';
import { cn } from '../lib/utils';

export default function Layout() {
  const { isLoading, isAuthenticated, user, logout } = useAuth0();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { userRole } = usePermissions();

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-900" />
      </div>
    );
  }

  // Don't show layout for public pages or onboarding
  if (location.pathname === '/' || location.pathname === '/onboarding' || !isAuthenticated) {
    return <Outlet />;
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Sidebar */}
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

      {/* Main Content */}
      <div className={cn(
        'transition-all duration-300',
        isSidebarOpen ? 'ml-64' : 'ml-20'
      )}>
        {/* Header */}
        <header className="bg-white shadow-sm">
          <div className="h-16 px-4 flex items-center justify-between">
            {/* Organization Switcher */}
            <OrganizationSwitcher />

            {/* Right Side Actions */}
            <div className="flex items-center space-x-4">
              {/* Notifications */}
              <NotificationBell />

              {/* User Menu */}
              <div className="flex items-center space-x-3">
                <div className="flex items-center space-x-3">
                  <span className="text-sm font-medium text-gray-900">
                    {user?.email}
                  </span>
                  <div className="inline-flex items-center justify-center w-8 h-8 bg-indigo-900 rounded-full text-white uppercase">
                    {user?.email?.charAt(0)}
                  </div>
                </div>

                {/* Logout Button */}
                <button
                  onClick={handleLogout}
                  className="p-2 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100 transition-colors"
                  title="Logout"
                >
                  <LogOut className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        </header>

        {/* Main Content Area */}
        <main className="p-6">
          <Outlet />
        </main>

        {/* Helper Mascot */}
        <ContextualMascot />
        <MascotToggle />
      </div>
    </div>
  );
}