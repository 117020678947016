import React, { useState, useRef, useEffect } from 'react';
import { Calendar as CalendarIcon } from 'lucide-react';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { cn } from '../../../lib/utils';
import type { DatePickerProps } from './types';
import './styles.css';

export function DatePicker({
  value,
  onChange,
  placeholder = 'Select date',
  isDisabled = false,
  error,
  label,
  required,
  minDate,
  maxDate,
  showTime = false,
  className,
  menuPlacement = 'bottom',
  menuOffset = 4
}: DatePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (date: Date | undefined) => {
    onChange(date || null);
    setIsOpen(false);
  };

  const getCalendarPosition = () => {
    if (menuPlacement === 'left') {
      return {
        right: 0,
        top: 'calc(100% + 4px)',
        marginRight: `${menuOffset}px`
      };
    }
    return {
      left: 0,
      top: 'calc(100% + 4px)',
      marginLeft: `${menuOffset}px`
    };
  };

  return (
    <div className={className} ref={containerRef}>
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </label>
      )}

      <div className="relative">
        <button
          type="button"
          onClick={() => !isDisabled && setIsOpen(!isOpen)}
          disabled={isDisabled}
          className={cn(
            'w-full flex items-center px-3 py-2 border rounded-md',
            'focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm',
            'transition-all duration-200 ease-in-out',
            isDisabled 
              ? 'bg-gray-50 cursor-not-allowed' 
              : 'bg-white hover:border-brand-400',
            error 
              ? 'border-red-300 focus:ring-red-500' 
              : 'border-gray-300 focus:ring-brand-500',
            isOpen && !error && 'border-brand-500 ring-2 ring-brand-500/20'
          )}
        >
          <CalendarIcon className={cn(
            'w-5 h-5 mr-2 transition-colors',
            error ? 'text-red-400' : 'text-gray-400',
            isOpen && !error && 'text-brand-500'
          )} />
          <span className={cn(
            'flex-1',
            !value && 'text-gray-500'
          )}>
            {value ? format(value, showTime ? 'PPp' : 'PP') : placeholder}
          </span>
        </button>

        {error && (
          <p className="mt-1 text-sm text-red-600">{error}</p>
        )}

        {isOpen && !isDisabled && (
          <div 
            className={cn(
              'absolute z-50 rounded-lg bg-white',
              'shadow-lg ring-1 ring-black ring-opacity-5',
              'transform opacity-100 scale-100',
              'transition-all duration-200 ease-in-out',
              'calendar-popup'
            )}
            style={getCalendarPosition()}
          >
            <DayPicker
              mode="single"
              selected={value || undefined}
              onSelect={handleSelect}
              disabled={[
                ...(minDate ? [{ before: minDate }] : []),
                ...(maxDate ? [{ after: maxDate }] : [])
              ]}
              modifiers={{
                today: new Date()
              }}
              modifiersStyles={{
                today: {
                  fontWeight: '600',
                  color: 'var(--brand-600)',
                  borderBottom: '2px solid var(--brand-400)'
                }
              }}
              styles={{
                months: { margin: '0.5rem' },
                caption: { position: 'relative' },
                caption_label: { fontWeight: '600', fontSize: '0.875rem' },
                nav: { display: 'flex', justifyContent: 'space-between' },
                nav_button: {
                  padding: '0.25rem',
                  border: 'none',
                  backgroundColor: 'transparent',
                  color: 'var(--gray-600)'
                },
                nav_button_previous: { marginRight: '0.5rem' },
                nav_button_next: { marginLeft: '0.5rem' },
                table: { width: '100%', borderSpacing: '0.25rem' },
                head_cell: {
                  width: '2.25rem',
                  fontSize: '0.75rem',
                  fontWeight: '600',
                  color: 'var(--gray-500)',
                  textTransform: 'uppercase'
                },
                cell: { width: '2.25rem', height: '2.25rem', fontSize: '0.875rem' },
                day: {
                  width: '2rem',
                  height: '2rem',
                  fontSize: '0.875rem',
                  borderRadius: '0.5rem',
                  transition: 'all 0.2s',
                  cursor: 'pointer'
                }
              }}
              className="p-3"
            />
          </div>
        )}
      </div>
    </div>
  );
}