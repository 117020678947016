import { Outlet } from 'react-router-dom';
import { HelpSidebar } from './HelpSidebar';

export function HelpLayout() {
  return (
    <div className="flex min-h-screen bg-gray-50">
      <HelpSidebar />
      <main className="flex-1 ml-64">
        <div className="py-8 px-4 sm:px-6 lg:px-8">
          <Outlet />
        </div>
      </main>
    </div>
  );
}