import { MascotMessage } from '../types';

export const CONTEXTUAL_MESSAGES: Record<string, MascotMessage[]> = {
  // Dashboard Context
  'dashboard': [
    {
      text: "Welcome to your dashboard! Need help understanding the widgets?",
      action: {
        type: 'navigate_tasks',
        data: { path: '/help/guide#dashboard-overview' }
      }
    },
    {
      text: "Pro tip: You can customize your dashboard layout! Want to learn how?",
      action: {
        type: 'navigate_tasks',
        data: { path: '/help/tutorials#dashboard-customization' }
      }
    }
  ],

  // Tasks Context
  'tasks': [
    {
      text: "Managing tasks effectively? Here are some best practices!",
      action: {
        type: 'navigate_tasks',
        data: { path: '/help/guide#task-management' }
      }
    },
    {
      text: "Did you know you can use filters to find tasks quickly?",
      action: {
        type: 'navigate_tasks',
        data: { path: '/help/tutorials#task-filters' }
      }
    }
  ],

  // Organization Context
  'organization': [
    {
      text: "Need help with organization settings? I can guide you!",
      action: {
        type: 'navigate_tasks',
        data: { path: '/help/guide#organization-settings' }
      }
    },
    {
      text: "Looking to manage team members? Check out our team management guide!",
      action: {
        type: 'navigate_tasks',
        data: { path: '/help/tutorials#team-management' }
      }
    }
  ],

  // Settings Context
  'settings': [
    {
      text: "Configuring your preferences? Let me help you get started!",
      action: {
        type: 'navigate_tasks',
        data: { path: '/help/guide#user-settings' }
      }
    },
    {
      text: "Want to learn about advanced settings? Check out our settings guide!",
      action: {
        type: 'navigate_tasks',
        data: { path: '/help/tutorials#advanced-settings' }
      }
    }
  ],

  // Subscription Context
  'subscription': [
    {
      text: "Questions about billing? Our subscription guide has answers!",
      action: {
        type: 'navigate_tasks',
        data: { path: '/help/guide#billing' }
      }
    },
    {
      text: "Want to compare plan features? Let me show you!",
      action: {
        type: 'navigate_tasks',
        data: { path: '/help/guide#plan-comparison' }
      }
    }
  ]
};

// Helper function to get contextual message
export function getContextualMessage(path: string): MascotMessage {
  const section = path.split('/')[1] || 'dashboard';
  const messages = CONTEXTUAL_MESSAGES[section];
  
  if (!messages || messages.length === 0) {
    return {
      text: "Need help? I'm here to assist! 👋"
    };
  }

  return messages[Math.floor(Math.random() * messages.length)];
}