import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useContextualMascot } from './hooks/useContextualMascot';
import { MascotEmotions } from './MascotEmotions';
import { MascotSpeechBubble } from './MascotSpeechBubble';
import { cn } from '../../lib/utils';
import './styles/mascot.css';

export function ContextualMascot() {
  const location = useLocation();
  const { emotion, message } = useContextualMascot();
  const [isHovered, setIsHovered] = useState(false);
  const [isBlinking, setIsBlinking] = useState(false);
  const [isWaving, setIsWaving] = useState(false);
  const [isVisible, setIsVisible] = useState(() => {
    const saved = localStorage.getItem('mascotVisible');
    return saved === null ? true : saved === 'true';
  });

  // Wave animation on mount and route changes
  useEffect(() => {
    setIsWaving(true);
    const timer = setTimeout(() => setIsWaving(false), 2000);
    return () => clearTimeout(timer);
  }, [location.pathname]);

  // Random blinking effect
  useEffect(() => {
    const blinkInterval = setInterval(() => {
      setIsBlinking(true);
      setTimeout(() => setIsBlinking(false), 200);
    }, Math.random() * 3000 + 2000);

    return () => clearInterval(blinkInterval);
  }, []);

  // Listen for visibility changes
  useEffect(() => {
    const handleVisibilityChange = (e: CustomEvent<{ isVisible: boolean }>) => {
      setIsVisible(e.detail.isVisible);
    };

    window.addEventListener('mascotVisibilityChange', handleVisibilityChange as EventListener);
    return () => {
      window.removeEventListener('mascotVisibilityChange', handleVisibilityChange as EventListener);
    };
  }, []);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  if (!isVisible) return null;

  return (
    <div 
      className={cn(
        'fixed bottom-4 right-4 z-50',
        'transition-all duration-300',
        'mascot-hover',
        isHovered && 'transform scale-105',
        isWaving && 'animate-wave',
        'animate-float'
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Speech Bubble */}
      <MascotSpeechBubble message={message} />

      {/* Mascot Face */}
      <div className="relative w-16 h-16">
        <MascotEmotions 
          emotion={emotion}
          isBlinking={isBlinking}
          isHovered={isHovered}
        />
      </div>
    </div>
  );
}