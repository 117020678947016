import { useState, useEffect } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import { cn } from '../../lib/utils';

export function MascotToggle() {
  const [isVisible, setIsVisible] = useState(() => {
    const saved = localStorage.getItem('mascotVisible');
    return saved === null ? true : saved === 'true';
  });

  useEffect(() => {
    localStorage.setItem('mascotVisible', isVisible.toString());
    // Dispatch event to notify other components
    window.dispatchEvent(new CustomEvent('mascotVisibilityChange', { 
      detail: { isVisible } 
    }));
  }, [isVisible]);

  return (
    <button
      onClick={() => setIsVisible(!isVisible)}
      className={cn(
        'fixed bottom-4 right-24 z-50',
        'p-2 rounded-full bg-white shadow-md border border-gray-200',
        'text-gray-600 hover:text-gray-900 transition-colors',
        'hover:bg-gray-50'
      )}
      title={isVisible ? 'Hide mascot' : 'Show mascot'}
    >
      {isVisible ? (
        <EyeOff className="w-4 h-4" />
      ) : (
        <Eye className="w-4 h-4" />
      )}
    </button>
  );
}