import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MascotMessage } from '../types';

const FAQ_SUGGESTIONS: Record<string, MascotMessage> = {
  'dashboard': {
    text: "Looking for dashboard help? Here are some popular questions:",
    suggestions: [
      "How do I customize my dashboard layout?",
      "What do the different widgets show?",
      "How can I track team performance?"
    ],
    relatedLinks: [
      { text: "Dashboard Guide", url: "/help/guide#dashboard" },
      { text: "Widget Tutorial", url: "/help/tutorials#widgets" }
    ]
  },
  'tasks': {
    text: "Need help with tasks? These questions might help:",
    suggestions: [
      "How do I assign tasks to team members?",
      "What are task priorities?",
      "How do I track task progress?"
    ],
    relatedLinks: [
      { text: "Task Management Guide", url: "/help/guide#tasks" },
      { text: "Task Tutorial", url: "/help/tutorials#task-management" }
    ]
  },
  'general': {
    text: "Need help finding answers? Here are some popular topics:",
    suggestions: [
      "Getting Started Guide",
      "Account Settings",
      "Team Management",
      "Billing & Subscription"
    ],
    relatedLinks: [
      { text: "Getting Started", url: "/help/guide#getting-started" },
      { text: "Video Tutorials", url: "/help/tutorials" }
    ]
  }
};

export function useFAQMascot() {
  const location = useLocation();
  const [currentSection, setCurrentSection] = useState<string>('general');

  useEffect(() => {
    const section = location.hash.slice(1) || 'general';
    setCurrentSection(section);
  }, [location]);

  return {
    currentSection,
    message: FAQ_SUGGESTIONS[currentSection] || FAQ_SUGGESTIONS.general
  };
}