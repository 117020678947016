import { useState, useCallback, useEffect } from 'react';
import { useTaskList } from '../../../hooks/task';
import type { MascotEmotion } from '../types';

export function useEmotionState() {
  const [emotion, setEmotion] = useState<MascotEmotion>('neutral');
  const [animate, setAnimate] = useState(false);
  const { tasks } = useTaskList();

  // Update emotion based on task status
  useEffect(() => {
    if (!tasks.length) return;

    const completedTasks = tasks.filter(task => task.status === 'completed');
    const blockedTasks = tasks.filter(task => task.status === 'blocked');
    const overdueTasks = tasks.filter(task => 
      task.dueDate && new Date(task.dueDate) < new Date() && task.status !== 'completed'
    );

    let newEmotion: MascotEmotion = 'neutral';

    if (blockedTasks.length > 0) {
      newEmotion = 'sad';
    } else if (overdueTasks.length > 0) {
      newEmotion = 'worried';
    } else if (completedTasks.length > 0) {
      newEmotion = 'happy';
    }

    if (newEmotion !== emotion) {
      setEmotion(newEmotion);
      setAnimate(true);
      setTimeout(() => setAnimate(false), 1000);
    }
  }, [tasks]);

  const updateEmotion = useCallback((newEmotion: MascotEmotion) => {
    if (newEmotion !== emotion) {
      setEmotion(newEmotion);
      setAnimate(true);
      setTimeout(() => setAnimate(false), 1000);
    }
  }, [emotion]);

  return {
    emotion,
    animate,
    updateEmotion
  };
}