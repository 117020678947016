import { useState, useEffect } from 'react';
import { 
  format, 
  startOfMonth, 
  endOfMonth, 
  eachDayOfInterval, 
  addMonths, 
  subMonths, 
  isToday,
  startOfWeek,
  endOfWeek,
  getDay
} from 'date-fns';
import { ChevronLeft, ChevronRight, Loader2, AlertCircle } from 'lucide-react';
import { TaskTimelineProps } from './types';
import { cn } from '../../../../lib/utils';
import { TimelineTask } from './TimelineTask';
import { Z_INDEX } from '../../../../constants/zIndex';

export function TaskTimeline({
  tasks,
  onTaskClick,
  isLoading,
  error,
  className
}: TaskTimelineProps) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [calendarHeight, setCalendarHeight] = useState<number>(0);
  
  // Calculate calendar height on mount to prevent layout jumps
  useEffect(() => {
    const rows = Math.ceil(new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate() / 7);
    setCalendarHeight(rows * 150); // 150px per row
  }, [currentDate]);

  const days = eachDayOfInterval({
    start: startOfWeek(startOfMonth(currentDate), { weekStartsOn: 1 }),
    end: endOfWeek(endOfMonth(currentDate), { weekStartsOn: 1 })
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-8">
        <Loader2 className="w-8 h-8 text-brand-600 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <AlertCircle className="h-5 w-5 text-red-400" />
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn('bg-white rounded-lg border border-gray-200 flex flex-col h-full', className)}>
      {/* Timeline Header */}
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <h2 className="text-lg font-semibold text-gray-900">
              {format(currentDate, 'MMMM yyyy')}
            </h2>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setCurrentDate(subMonths(currentDate, 1))}
                className="p-1 rounded-md hover:bg-gray-100 text-gray-500 hover:text-gray-700"
              >
                <ChevronLeft className="w-5 h-5" />
              </button>
              <button
                onClick={() => setCurrentDate(new Date())}
                className="px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md"
              >
                Today
              </button>
              <button
                onClick={() => setCurrentDate(addMonths(currentDate, 1))}
                className="p-1 rounded-md hover:bg-gray-100 text-gray-500 hover:text-gray-700"
              >
                <ChevronRight className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Calendar Grid */}
      <div className="flex-1 overflow-auto">
        <div className="min-w-[800px]">
          {/* Day Headers */}
          <div 
            className="grid grid-cols-7 border-b border-gray-200 sticky top-0 bg-white"
            style={{ zIndex: Z_INDEX.CONTENT }}
          >
            {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
              <div key={day} className="py-2 text-center">
                <span className="text-sm font-medium text-gray-500">{day}</span>
              </div>
            ))}
          </div>

          {/* Calendar Days */}
          <div 
            className="grid grid-cols-7"
            style={{ minHeight: `${calendarHeight}px` }}
          >
            {days.map((day) => {
              const dayTasks = tasks.filter(task => 
                task.dueDate && format(new Date(task.dueDate), 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd')
              );
              const isCurrentMonth = day.getMonth() === currentDate.getMonth();

              return (
                <div 
                  key={day.toString()}
                  className={cn(
                    'min-h-[150px] p-2 border-b border-r border-gray-200',
                    isToday(day) && 'bg-blue-50',
                    !isCurrentMonth && 'bg-gray-50/50'
                  )}
                >
                  {/* Date Number */}
                  <div className="text-right mb-2">
                    <span className={cn(
                      'inline-flex h-6 w-6 items-center justify-center rounded-full text-sm',
                      isToday(day)
                        ? 'bg-brand-600 text-white'
                        : isCurrentMonth
                          ? 'text-gray-700'
                          : 'text-gray-400'
                    )}>
                      {format(day, 'd')}
                    </span>
                  </div>

                  {/* Tasks */}
                  <div className="space-y-1">
                    {dayTasks.map(task => (
                      <TimelineTask
                        key={task.id}
                        task={task}
                        onClick={() => onTaskClick?.(task)}
                      />
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}