import { Play, Clock } from 'lucide-react';
import { cn } from '../../lib/utils';

const TUTORIALS = [
  {
    id: 'getting-started',
    title: 'Getting Started Guide',
    description: 'Learn the basics of navigating and using the platform',
    duration: '5:30',
    thumbnail: '/tutorials/getting-started.jpg'
  },
  {
    id: 'task-management',
    title: 'Task Management',
    description: 'Master creating and organizing tasks efficiently',
    duration: '8:45',
    thumbnail: '/tutorials/task-management.jpg'
  },
  {
    id: 'team-collaboration',
    title: 'Team Collaboration',
    description: 'Best practices for working with your team',
    duration: '6:15',
    thumbnail: '/tutorials/team-collaboration.jpg'
  }
];

export function VideoTutorials() {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-lg font-medium text-gray-900 mb-6">
        Video Tutorials
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {TUTORIALS.map((tutorial) => (
          <div
            key={tutorial.id}
            className={cn(
              'rounded-lg overflow-hidden',
              'border border-gray-200',
              'hover:shadow-md transition-shadow',
              'group'
            )}
          >
            {/* Thumbnail */}
            <div className="relative aspect-video bg-gray-100">
              <div className={cn(
                'absolute inset-0 flex items-center justify-center',
                'bg-black bg-opacity-0 group-hover:bg-opacity-30',
                'transition-all duration-200'
              )}>
                <Play className={cn(
                  'w-12 h-12 text-white opacity-0 group-hover:opacity-100',
                  'transform scale-75 group-hover:scale-100',
                  'transition-all duration-200'
                )} />
              </div>
            </div>

            {/* Content */}
            <div className="p-4">
              <h3 className="font-medium text-gray-900 mb-1">
                {tutorial.title}
              </h3>
              <p className="text-sm text-gray-500 mb-3">
                {tutorial.description}
              </p>
              <div className="flex items-center text-sm text-gray-400">
                <Clock className="w-4 h-4 mr-1" />
                {tutorial.duration}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}