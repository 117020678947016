import { useState } from 'react';
import { ChevronRight } from 'lucide-react';
import { cn } from '../../lib/utils';

const GUIDE_SECTIONS = [
  {
    id: 'dashboard',
    title: 'Dashboard Overview',
    content: [
      {
        title: 'Understanding Your Dashboard',
        description: 'Learn about the different widgets and how to customize your dashboard layout.',
        steps: [
          'Task Overview Widget shows your task distribution',
          'Team Workload displays team member assignments',
          'Timeline provides a calendar view of tasks',
          'Analytics offers insights into team performance'
        ]
      },
      {
        title: 'Customizing Your Dashboard',
        description: 'Make the dashboard work for you by customizing its layout and widgets.',
        steps: [
          'Click "Customize Dashboard" to begin',
          'Drag and drop widgets to rearrange',
          'Toggle widget visibility',
          'Save your preferred layout'
        ]
      }
    ]
  },
  {
    id: 'tasks',
    title: 'Task Management',
    content: [
      {
        title: 'Creating and Managing Tasks',
        description: 'Learn how to create, edit, and organize tasks effectively.',
        steps: [
          'Click "New Task" to create a task',
          'Set priority, due date, and assignees',
          'Add custom fields and attachments',
          'Use subtasks for complex items'
        ]
      },
      {
        title: 'Task Views and Filters',
        description: 'Different ways to view and organize your tasks.',
        steps: [
          'Switch between List, Grid, and Kanban views',
          'Use filters to find specific tasks',
          'Save custom views for quick access',
          'Sort and group tasks by various criteria'
        ]
      }
    ]
  }
];

export function GuideContent() {
  const [activeSection, setActiveSection] = useState(GUIDE_SECTIONS[0].id);

  return (
    <div className="bg-white rounded-lg shadow">
      {/* Section Navigation */}
      <div className="border-b border-gray-200">
        <nav className="flex space-x-8 px-6" aria-label="Guide sections">
          {GUIDE_SECTIONS.map((section) => (
            <button
              key={section.id}
              onClick={() => setActiveSection(section.id)}
              className={cn(
                'py-4 px-1 border-b-2 font-medium text-sm whitespace-nowrap',
                activeSection === section.id
                  ? 'border-brand-500 text-brand-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              )}
            >
              {section.title}
            </button>
          ))}
        </nav>
      </div>

      {/* Content */}
      <div className="p-6">
        {GUIDE_SECTIONS.find(s => s.id === activeSection)?.content.map((item, index) => (
          <div 
            key={index}
            className={cn(
              'mb-8 last:mb-0',
              'p-6 rounded-lg',
              'border border-gray-100',
              'hover:border-brand-100 hover:bg-brand-50/10',
              'transition-colors duration-200'
            )}
          >
            <h3 className="text-lg font-medium text-gray-900 mb-2">
              {item.title}
            </h3>
            <p className="text-gray-600 mb-4">
              {item.description}
            </p>
            <div className="space-y-3">
              {item.steps.map((step, stepIndex) => (
                <div 
                  key={stepIndex}
                  className="flex items-start"
                >
                  <ChevronRight className="w-5 h-5 text-brand-500 flex-shrink-0 mt-0.5" />
                  <span className="ml-2 text-gray-700">{step}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}