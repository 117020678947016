import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MascotMessage } from '../types';

const TUTORIAL_HIGHLIGHTS: Record<string, MascotMessage> = {
  'getting-started': {
    text: "Pro tip! 💡 Don't forget to customize your dashboard after completing this tutorial.",
    suggestions: [
      "Dashboard customization tips",
      "Setting up your workspace",
      "Inviting team members"
    ],
    relatedLinks: [
      { text: "Dashboard Guide", url: "/help/guide#dashboard" },
      { text: "Advanced Settings", url: "/help/tutorials#settings" }
    ]
  },
  'task-management': {
    text: "Here's a helpful resource! Check out our advanced task management guide.",
    suggestions: [
      "Task automation tips",
      "Using task templates",
      "Task prioritization strategies"
    ],
    relatedLinks: [
      { text: "Advanced Tasks", url: "/help/tutorials#advanced-tasks" },
      { text: "Task FAQ", url: "/help/faq#tasks" }
    ]
  },
  'default': {
    text: "Want to learn more? I can suggest some related tutorials! 📚",
    suggestions: [
      "Getting Started Guide",
      "Task Management Basics",
      "Team Collaboration Tips"
    ],
    relatedLinks: [
      { text: "All Tutorials", url: "/help/tutorials" },
      { text: "FAQ", url: "/help/faq" }
    ]
  }
};

export function useTutorialMascot() {
  const location = useLocation();
  const [currentTutorial, setCurrentTutorial] = useState<string>('default');

  useEffect(() => {
    const tutorial = location.hash.slice(1) || 'default';
    setCurrentTutorial(tutorial);
  }, [location]);

  return {
    currentTutorial,
    message: TUTORIAL_HIGHLIGHTS[currentTutorial] || TUTORIAL_HIGHLIGHTS.default
  };
}