import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MessageCircle } from 'lucide-react';
import { cn } from '../../../lib/utils';

export function ContactPrompt() {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className={cn(
        'bg-brand-50/50 rounded-lg p-4',
        'border border-brand-100',
        'transition-all duration-200',
        'cursor-pointer',
        isHovered && 'bg-brand-50 shadow-sm'
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => navigate('/help/support')}
      role="button"
      tabIndex={0}
    >
      <div className="flex items-center space-x-3">
        <MessageCircle className="w-5 h-5 text-brand-600" />
        <div>
          <h4 className="text-sm font-medium text-brand-900">
            Need help?
          </h4>
          <p className="text-sm text-brand-600">
            I'm here to assist you!
          </p>
        </div>
      </div>
      <button
        className={cn(
          'mt-3 text-sm text-brand-600 hover:text-brand-700',
          'flex items-center space-x-1',
          'transition-colors duration-200'
        )}
      >
        <span>Contact support</span>
        <span aria-hidden="true">→</span>
      </button>
    </div>
  );
}